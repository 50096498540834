<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader title="Kullanıcı Grubu Yönetimi" />
    <Main>
      <a-button
        type="primary"
        style="position: absolute;top: 30px;right: 30px"
        @click="show_create_form = true"
      >
        <sdFeatherIcons
          size="12"
          type="plus"
          style="color:#ffffff;margin-right: 2px;"
        />
        Üye Grubu Ekle
      </a-button>

      <a-row
        v-if="!userGroupList.length"
        type="flex"
        justify="center"
        :gutter="12"
        align="top"
      >
        <a-col :span="16">
          <!--          asd-->
          <a-button
            type="dashed"
            ghost
            block
            @click="show_create_form = true"
          >
            Üye Grubu Bulunamadı<span style="color:#5f63f2;font-weight: bold"> Şimdi Ekle</span>
          </a-button>
        </a-col>
        <a-col :span="16">
          <img
            alt="example"
            src="@/img/company-not-found.png"
            style="width: 100%;"
          >
        </a-col>
      </a-row>

      <SocialMediaWrapper>
        <sdCards title="Üye Grupları">
          <a-row :gutter="25">
            <a-col
              v-for="item in userGroupList"
              :key="item.id"
              :xxl="8"
              :md="4"
              :xs="8"
            >
              <SocialMediaContent
                bg-color="linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)"
                :title="item.name"
                sub-title="GRUP"
                style="cursor: pointer"
                @click="editUserGroupName(item.id)"
              />
            </a-col>
          </a-row>
        </sdCards>
      </SocialMediaWrapper>
    </Main>
  </a-spin>
  <a-modal
    v-model:visible="show_edit_form"
    title="Düzenle"
    :confirm-loading="false"
  >
    <a-form layout="vertical">
      <a-form-item label="Grup Adı">
        <a-input v-model:value="edit_form_state.name" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="show_edit_form = false">
        İptal
      </a-button>
      <a-button
        type="primary"
        @click="updateUserGroupName"
      >
        Kaydet
      </a-button>
    </template>
  </a-modal>
  <a-modal
    v-model:visible="show_create_form"
    title="Üye Grubu"
    :confirm-loading="false"
    ok-text="Kaydet"
    cancel-text="İptal"
    @ok="saveUserGroupName"
    @cancel="show_create_form = false"
  >
    <a-form layout="vertical">
      <a-form-item label="Grup Adı">
        <a-input v-model:value="create_form_state.name" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { Main } from '@/view/styled'
import { SocialMediaWrapper } from '../dashboard/style'
import SocialMediaContent from '@/components/social-media/overview'

export default {
  components: {
    Main,
    SocialMediaWrapper,
    SocialMediaContent,
  },
  data() {
    return {
      userGroupList: [],
      isLoading: false,
      show_create_form: false,
      show_edit_form: false,
      create_form_state: {
        name: '',
      },
      edit_form_state: {
        id: '',
        name: '',
      },
    }
  },
  mounted() {
    this.getUserGroupName()
  },
  methods: {
    getUserGroupName() {
      this.isLoading = true
      this.$restMethods.postForNoc('/get-user-group').then(response => {
        if ( ! response.hasError()) {
          this.userGroupList = response.getData().groups
        }
        this.isLoading = false
      })
    },
    saveUserGroupName() {
      if (this.create_form_state.name === '' || this.create_form_state.name === null) {
        this.$message.error('Lütfen Grup Adı Giriniz')
        return
      }
      this.isLoading = true
      this.$restMethods.postForNoc('/create-user-group', this.create_form_state).then(resposne => {
        if ( ! resposne.hasError()) {
          this.$message.success(resposne.getData().message)
          this.getUserGroupName()
          this.show_create_form = false
        }
        this.isLoading = false
      })
    },
    editUserGroupName(id) {
      this.$restMethods.postForNoc('/edit-user-group-open/' + id).then(response => {
        if ( ! response.hasError()) {
          this.edit_form_state.id = id
          this.edit_form_state.name = response.getData().form.name

          this.show_edit_form = true
        }
      })
    },
    updateUserGroupName() {
      this.isLoading = true
      this.$restMethods.postForNoc('/edit-user-group-submit/' + this.edit_form_state.id, {
        name: this.edit_form_state.name,
      }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(response.getData().message)
          this.show_edit_form = false
          this.getUserGroupName()
        }
        this.isLoading = false
      })
    },
  },
}
</script>
